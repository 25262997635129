import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouter } from "next/router"
import { fetchUserByToken } from "@/store/slice/user"
import authService from "@/services/authService"
// import useSWR from "swr"

const useUser = () => {
  const dispatch = useDispatch()
  // const { data: user, mutate: mutateUser } = useSWR("/api/user")
  const user = useSelector(state => state.user)
  const { isLoggedIn } = useSelector(state => state.auth)
  const router = useRouter()
  // useEffect(() => {
  //   // if no redirect needed, just return (example: already on /dashboard)
  //   // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
  //   if (!redirectTo || !user) return

  // if (
  //   // If redirectTo is set, redirect if the user was not found.
  //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
  //   // If redirectIfFound is also set, redirect if the user was found
  //   (redirectIfFound && user?.isLoggedIn)
  // ) {
  //   Router.push(redirectTo)
  // }
  // }, [user, redirectIfFound, redirectTo])
  const isPublicPaths = asPath => {
    const publicPaths = [
      "/",
      "/account/login",
      "/account/register",
      "/email-confirmation",
      "/email-reset-password",
    ]
    const path = asPath.split("?")[0]

    return publicPaths.includes(path)
  }

  const authCheck = asPath => {
    if (!authService.authValue()) {
      localStorage.setItem("returnUrl", JSON.stringify(asPath))

      router.push({
        pathname: "/account/login",
        query: { returnUrl: asPath },
      })
    }
  }

  // Get user
  useEffect(() => {
    if (!router.isReady) return
    if (isPublicPaths(router.asPath)) return

    authCheck(router.asPath)

    if (isLoggedIn && !user.currentUser) {
      if (user.isFetching || user.isSuccess) return
      if (user.isError) {
        return
      }
      dispatch(fetchUserByToken())
    }
  }, [isLoggedIn, user, router])

  return { currentUser: user?.currentUser, isFetching: user?.isFetching, isError: user?.isError }
}

export default useUser
