import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

export const Dialog = ({
  open,
  title,
  onClose,
  actions,
  children,
  fullWidth = true,
  maxWidth = "sm",
  actionsJustifyContent = "center",
  fullScreenSize = "sm",
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenSize))

  return (
    <>
      <MuiDialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} fullScreen={fullScreen}>
        <DialogTitle sx={{ m: "auto" }}>
          {title}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent sx={{ px: { xs: 0, sm: 2, md: 3 } }}>{children}</DialogContent>
        <DialogActions sx={{ justifyContent: actionsJustifyContent }}>{actions}</DialogActions>
      </MuiDialog>
    </>
  )
}
