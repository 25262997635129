import { Container, Typography, Box } from "@mui/material"

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        mt: "auto",
        color: theme => theme.footer.fontColor,
        backgroundColor: theme => theme.footer.backgroundColor,
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Container>
        <Typography align="center">
          @{new Date().getFullYear()} - PinMed All Right Reserved
        </Typography>
      </Container>
    </Box>
  )
}

export default Footer
