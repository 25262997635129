import { useState } from "react"
import { useDispatch } from "react-redux"
import Image from "next/legacy/image"
import { Button, Box, Link } from "@mui/material"
import { Dialog } from "@/components/overlays"
import { enqueueSnackbar } from "@/store/slice/snackbar"
import { logout } from "@/store/slice/auth"
import { resendConfirmationEmail } from "@/services/userService"

const VerifyEmailDialog = ({ currentUser }) => {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const dispatch = useDispatch()
  const handleLogout = () => {
    dispatch(logout())
  }

  const handleResendConfirmationEmail = async () => {
    try {
      await resendConfirmationEmail()
      setIsEmailSent(true)
      dispatch(
        enqueueSnackbar({
          message: "信箱驗證信已寄出。",
          options: {
            variant: "success",
          },
        })
      )
    } catch (e) {
      dispatch(
        enqueueSnackbar({
          message:
            e === "confirmation email already send" ? "驗證信已寄出，請稍後再試。" : "發生錯誤。",
          options: {
            variant: "error",
          },
        })
      )
    }
  }

  return (
    <Dialog
      open={true}
      title={isEmailSent ? "信箱驗證信已傳送" : "請驗證信箱"}
      fullScreenSize="xs"
      actions={
        <Box
          sx={{
            display: "flex",
            gap: 4,
          }}
        >
          {isEmailSent ? (
            <Button variant="outlined" onClick={handleResendConfirmationEmail}>
              重新發送驗證信
            </Button>
          ) : (
            <>
              {currentUser?.email ? (
                <Button variant="contained" onClick={handleResendConfirmationEmail}>
                  發送驗證信
                </Button>
              ) : (
                ""
                // <Button variant="contained" href={"/account/settings"}>
                //   前往帳號設定新增Email
                // </Button>
              )}
            </>
          )}

          <Link
            underline="none"
            color="SHADES_400"
            variant="headingSmallRegular"
            onClick={handleLogout}
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            登出
          </Link>
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isEmailSent ? (
          <>
            <Image src="/admin/email-sent.png" width="200" height="200" alt="email sent" />
            <p>
              驗證信已傳送至您的註冊信箱（{currentUser?.email}），請點擊信中的連結以完成驗證。
              {/* <br />
              收不到認證信？ 前往
              <Link variant="outlined" href={"/account/settings"}>
                帳號設定
              </Link>
              修改Email 或 重新發送認證信 */}
            </p>
          </>
        ) : (
          <p>
            請先進行信箱驗證，以開始建立頁面。
            {/* <br />
            或前往
            <Link variant="outlined" href={"/account/settings"}>
              帳號設定
            </Link>
            修改Email */}
          </p>
        )}
      </Box>
    </Dialog>
  )
}

export default VerifyEmailDialog
