import { useDispatch } from "react-redux"
import { Box, Button } from "@mui/material"
import { Dialog } from "@/components/overlays"
import { logout } from "@/store/slice/auth"

const UserErrorDialog = () => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <Dialog
      open={true}
      title={"發生錯誤。請重新登入"}
      actions={
        <Box
          sx={{
            display: "flex",
            gap: 4,
          }}
        >
          <Button variant="contained" onClick={handleLogout}>
            登出
          </Button>
        </Box>
      }
    ></Dialog>
  )
}

export default UserErrorDialog
