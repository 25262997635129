import { useState } from "react"
import { useRouter } from "next/router"
import { AppBar, Box, Toolbar, Tooltip, Avatar, Menu, MenuItem } from "@mui/material"
import ListItemIcon from "@mui/material/ListItemIcon"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import { Logout } from "@mui/icons-material"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import Link from "next/link"
import Image from "next/legacy/image"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "@/store/slice/auth"
import { toggleMobileDrawerOpen } from "@/store/slice/ui"

const Header = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector(state => state.auth)
  const { mobileDrawerOpen } = useSelector(state => state.app.ui)

  const [anchorElUser, setAnchorElUser] = useState(null)

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleDrawerToggle = () => {
    dispatch(toggleMobileDrawerOpen(!mobileDrawerOpen))
  }

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme => theme.header.backgroundColor,
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Box sx={{ display: "flex", alignItems: "center", mr: "auto" }}>
          {isLoggedIn &&
            !["/home", "/clinic/application", "/doctor/application"].some(v =>
              router.asPath.includes(v)
            ) && (
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            )}
          <Link href={isLoggedIn ? "/home" : "/account/login"} sx={{ display: "flex" }}>
            <Image src="/Pinmed-logo.svg" alt="Pinmed Logo" width="128" height="32" />
          </Link>
        </Box>

        {isLoggedIn ? (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="開啟選單">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem component={Link} color="SHADES_700" href="/home">
                <ListItemIcon>
                  <HomeOutlinedIcon fontSize="small" />
                </ListItemIcon>
                我的頁面
              </MenuItem>
              {/* <MenuItem component={NextLink} color="SHADES_700" href="/account/settings">
                <ListItemIcon>
                  <PermIdentityOutlinedIcon fontSize="small" />
                </ListItemIcon>
                帳號設定
              </MenuItem> */}
              <MenuItem onClick={handleLogout} color="SHADES_700">
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                登出
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          !["/account/login", "/account/register"].includes(router.asPath) && (
            <Box sx={{ display: "flex" }}>
              <Link
                underline="none"
                color="SHADES_400"
                variant="body16PxRegular"
                sx={{ display: "flex", alignItems: "center", cursor: "pointer", p: 2 }}
                href={"/account/login"}
              >
                登入
              </Link>
              <Link
                underline="none"
                color="SHADES_400"
                variant="body16PxRegular"
                sx={{ display: "flex", alignItems: "center", cursor: "pointer", p: 2 }}
                href={"/account/register"}
              >
                註冊
              </Link>
            </Box>
          )
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
