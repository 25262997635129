import { useRef } from "react"
import Head from "next/head"
import { SnackbarProvider } from "notistack"

import { Toolbar, IconButton, Box, CircularProgress } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import Notifier from "@/components/layouts/Notifier"
// import dynamic from "next/dynamic"
// const Header = dynamic(() => import("@/components/layouts/Header"), { ssr: false })
import Header from "@/components/layouts/Header"
import Footer from "@/components/layouts/Footer"

import VerifyEmailDialog from "@/components/layouts/VerifyEmailDialog"
import UserErrorDialog from "@/components/layouts/UserErrorDialog"
import useUser from "@/lib/useUser"

const Layout = ({ children, withoutEmailVerified }) => {
  const { currentUser, isFetching, isError } = useUser()
  const notistackRef = useRef()
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <>
      <Head>
        <title>PinMed - 管理後台</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <meta content="minimum-scale=1, initial-scale=1, width=device-width" name="viewport" />
        <meta property="og:title" content="PinMed - 管理後台" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://pinmed.co/images/landing-page-og-image.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Head>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header user={currentUser} />

        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          action={key => (
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={onClickDismiss(key)}
            >
              <CloseIcon />
            </IconButton>
          )}
        >
          <Notifier />
          <Toolbar />

          {isFetching ? (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          ) : (
            <>
              {children}

              {currentUser && currentUser.confirmedAt === "0001-01-01" && !withoutEmailVerified && (
                <VerifyEmailDialog currentUser={currentUser} />
              )}
            </>
          )}

          {isError && <UserErrorDialog />}
        </SnackbarProvider>

        <Footer />
      </Box>
    </>
  )
}

export default Layout
